import {
  Background,
  BackgroundCoverColor,
  BackgroundCoverImage,
  AuthContainer,
} from './styled';

interface AuthLayoutProps {
  children: React.ReactNode;
  isDark?: boolean;
}

function AuthLayout({ children, isDark = false }: AuthLayoutProps) {
  return (
    <AuthContainer>
      <BackgroundCoverImage isDark={isDark} />
      <BackgroundCoverColor isDark={isDark} />
      <Background>{children}</Background>
    </AuthContainer>
  );
}

export default AuthLayout;
