import color from '@deprecated-ui/_style/color';
import { assetPrefix } from 'constants/app';
import styled, { css } from 'styled-components';

interface BackgroundCoverProps {
  isDark?: boolean;
}

const AuthContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

const BackgroundCoverImage = styled.div<BackgroundCoverProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: cover;
  background-position: right bottom;
  background-repeat: no-repeat;

  ${({ isDark }) => {
    if (isDark) {
      return css`
        background-image: url('${assetPrefix}/images/idx-dark.jpg');
        filter: none;
      `;
    }

    return css`
      background-image: url('${assetPrefix}/images/idx.jpg');
      filter: blur(5px);
    `;
  }}
`;

const BackgroundCoverColor = styled.div<BackgroundCoverProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: ${color.light};
  opacity: 0.93;

  ${({ isDark }) =>
    isDark &&
    `
    background: none;
    opacity: 1;
  `}
`;

const Background = styled.div`
  -webkit-animation-duration: 1s;
  animation-duration: 1s;

  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  -webkit-animation-name: loginbackx;
  animation-name: loginbackx;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;

  max-height: 100vh;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0;
  }
`;

export {
  Background,
  BackgroundCoverColor,
  BackgroundCoverImage,
  AuthContainer,
};
